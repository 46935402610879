<script setup>
import { onBeforeMount, ref } from "vue";

const props = defineProps({
  cart_item: {},
  index: 0,
});

const price = ref();
const qtty = ref();
const ppId = ref();
const partial = ref(0);
const available = ref();
const index = ref();

const emit = defineEmits(["itemQttyChange"]);

onBeforeMount(() => {
  qtty.value = parseInt(props.cart_item.qtty);
  partial.value = number_format(qtty.value * props.cart_item.price);
  price.value = number_format(props.cart_item.price * 1);

  ppId.value = props.cart_item.ppId;

  available.value = parseInt(props.cart_item.available ?? -1);
  index.value = parseInt(props.index);
});

function number_format(number) {
  const locale = navigator.language;
  const formatoLocal = number.toLocaleString(locale, {
    style: "currency",
    currency: "ARS",
    currencyDisplay: "symbol",
  });
  return formatoLocal.replace(/[^\d.,]/g, "");
}
</script>

<template>
  <div class="row stripped my-3 py-2 mx-2 py-md-0 my-md-0">
    <div class="col-12 pb-1">
      <div class="row px-3">
        <div class="col-12 col-md-4">
          {{ cart_item.item.name }}
        </div>
        <div class="col-12 col-md-2">
          <label class="text-danger presentation pl-3">{{
            cart_item.Presentacion
          }}</label>
        </div>
        <div class="d-none col-1 d-md-block category">
          {{ cart_item.item.category }}
        </div>
        <div class="d-none col-12 col-md-1 d-md-block text-end">{{ qtty }}</div>

        <div class="row col-12 d-inline d-md-none text-end">
          <div class="d-inline text-end">Cantidad:</div>
          <div class="d-inline text-end">
            {{ qtty }}
          </div>
        </div>

        <div class="d-none d-md-block col-md-2 text-end price">
          ${{ price }}
        </div>

        <div class="row col-12 d-inline d-md-none text-end">
          <div class="d-inline text-end price">Precio unitario:</div>
          <div class="d-inline text-end price">${{ price }}</div>
        </div>

        <div class="d-none d-md-block col-md-2 text-end partial">
          ${{ partial }}
        </div>

        <div class="row col-12 d-inline d-md-none text-end">
          <div class="d-inline text-end partial">Total parcial:</div>
          <div class="d-inline text-end partial">${{ partial }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.name {
  color: #334756;
  font-weight: bold;
  font-size: 14px;
}
.category {
  color: #334756;
  text-transform: uppercase;
  font-size: 14px;
}
.price {
  color: #334756;
  font-weight: bold;
  font-size: 16px;
}
.presentation {
  color: red;
  font-size: 12px;
}
.partial {
  color: deepskyblue;
  font-weight: bold;
  font-size: 18px;
}

.stripped {
  border-radius: 15px;
}

.stripped:nth-child(odd) {
  background: ghostwhite;
  border-radius: 15px;
}
</style>
