<script setup>
import CompraListItems from "@components/shopping_cart/CompraListItems.vue";
import { useShoppingCartStore } from "@composables/use_shopping_cart_store";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";

const router = useRouter();

const { shoppingCartItems, shoppingCartTotal, tipoPago, idVenta } =
  useShoppingCartStore();

const emit = defineEmits(["oncloseshoppingcart", "onProcessCart"]);

function onClose() {
  let btnMP = document.getElementById("btnMP");
  if (btnMP) {
    btnMP.classList.remove("disabled");
  }
  let btnOtrosPagos = document.getElementById("btnOtrosPagos");
  if (btnOtrosPagos) {
    btnMP.classList.remove("disabled");
  }
  console.log("shopping cart modal close");
  emit("oncloseshoppingcart");
}

onMounted(() => {
  idVenta.value = localStorage.getItem("saleId");
});

function number_format(number) {
  const locale = navigator.language;
  const formatoLocal = number.toLocaleString(locale, {
    style: "currency",
    currency: "ARS",
    currencyDisplay: "symbol",
  });
  return formatoLocal.replace(/[^\d.,]/g, "");
}
</script>

<template>
  <div class="dialog-dismiss" @click="onClose">
    <div
      class="dialog-container"
      @click.stop
      style="max-height: 100%; overflow-y: auto"
    >
      <div class="row">
        <div class="col-12 col-md-4">
          <span class="dialog-title">Detalles de la Compra </span>
          <span v-if="idVenta != ''" class="dialog-title"
            >No. {{ idVenta }}
          </span>
        </div>
        <div class="col-12 col-md-4">
          <span v-if="tipoPago != ''">
            <span v-if="tipoPago == 'OtrosMedios'" class="px-md-5"
              >(Otros medios de Pago)</span
            >
            <span v-else class="px-5">(Mercado Pago) --- {{ tipoPago }} </span>
          </span>
        </div>

        <div
          style="color: red; background-color: cornsilk; font-size: 18px"
          class="col-11 mx-3 col-md-11 mt-2 py-2 mx-md-5 rounded"
        >
          Atención!! Aún debes realizar el pago para poder entregarte la compra.
        </div>
      </div>

      <div class="row mx-2 pt-3">
        <div class="col-12 col-sm-8 col-md d-md-block pb-1 d-none">
          <div class="row px-3">
            <div class="col-4 small">
              <strong>Producto</strong>
            </div>
            <div class="col-2 small">
              <label class="text-danger pl-3"></label>
            </div>
            <div class="col-1 small">
              <strong>Categoría</strong>
            </div>
            <div class="col-1 text-end small">
              <strong>Cantidad</strong>
            </div>
            <div class="col-2 text-end small"><strong>Precio</strong></div>
            <div class="col-2 text-end small">
              <strong>Importe parcial</strong>
            </div>
          </div>
        </div>
      </div>

      <CompraListItems
        v-for="(cart, index) in shoppingCartItems"
        :key="`cart_${index}`"
        :cart_item="cart"
        :index="index"
      />
      <div class="row justify-content-end px-4">
        <div class="col-12 total">
          Total: ${{ number_format(shoppingCartTotal) }}
        </div>
      </div>

      <div class="form-group row mx-3">
        <div
          class="col-md-6"
          style="
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
          "
        >
          <button class="btn standard-btn cancel-btn" @click.stop="onClose">
            Cerrar
          </button>
        </div>

        <div class="col-md-6 text-end"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.total {
  text-align: end;
  color: green;
  font-weight: bold;
  font-size: 22px;
}
</style>
